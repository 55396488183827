<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <h1>{{fullname}}</h1>
        <div>Username: {{ user.userName }} | Last Connected: {{ user.lastConnected | unixToLocalDt }}</div>
        <div v-if="user.roles && user.roles.length > 0">Roles: {{ user.roles | formatArray }}</div>
        <div>Has Password: {{ user.hasPassword }}</div>
      </v-col>
      <v-col cols="12">
        <contact-info :altUser="user"></contact-info>
      </v-col>
      <v-col cols="12" v-if="user.pages && user.pages.length > 0">
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>Organizations</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="px-0">
            <v-list>
              <template  v-for="page in user.pages">
                <v-list-item :key="page.id" :to="{ name: 'organization-settings', params: { username: page.username }}">
                  <v-list-item-content style="max-width: 160px;">
                    <v-img
                      :src="getLogoUrl(page.username)"
                      contain
                      max-height="50px"
                      max-width="150px"
                    ></v-img>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{page.name}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="`${page.id}-d`"></v-divider>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { api } from '@/classes/_URL/index.js'
const ContactInfo = () => import('@/components/User/ContactInfo')

export default {
  props: ['user'],
  computed: {
    fullname () {
      return [this.user.firstName, this.user.lastName].join(' ')
    }
  },
  methods: {
    getLogoUrl (username) {
      return `${api}/organization/${username}/logo`
    }
  },
  components: {
    ContactInfo
  }
}
</script>
